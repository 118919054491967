import { Api } from '@api/ApiTransport';
import { CONTRACT_TYPE } from '@constants/CONTRACT';
import dayjs from 'dayjs';
import { create } from 'zustand';
import { useStoreError } from './storeError';

const api = Api.getInstance();

export type SummaryContract = {
    type: string;
    active: number;
    pending: number;

}

export type Contract = {
    id: number;
    createdAt: string;
    updatedAt: string | null;
    status: number;
    idUser: number;
    typeOwner?: string | null;
    price?: number;
    urlSign: string;
    idUtilities: string;
    startDate?: string | null;
    documents: any[];
    // info: object | null;
    info: any | null;
    iban?: string;
};

export type Summary = {
    user: {
        name: string;
        surname: string;
        id: number;
        gestito?: boolean;
    },
    allContracts: SummaryContract[],
}

export type ContractsState = {
    contracts: Contract[];
    activeContract: Contract | null;
    summary: Summary[] | null;
    friendsContracts: any[];
    resetHome: () => void;
    info: {
        pendingContracts: number;
        redeem: number;
        totale: number;
        price: number | null;
        friends: number;
        activeRequestFunds: boolean;
        userFundsGiven: number[];
    }
    setFriends: (idFriend: number) => void;
    getContracts: () => Promise<void>;
    getActiveContract: (id: string | number, callback?: () => void) => Promise<void>;
    getBalance: () => Promise<void>;
    getFriendsContracts: () => Promise<void>;
    getHomeContracts: () => Promise<void>;
    updatePaymentMethod: (payload: any, id: number, callback: () => void) => Promise<void>;
    // getSessionById: (id: number) => Promise<void>;
}

export const useContractsStore = create<ContractsState>((set, get) => ({
    contracts: [],
    activeContract: null,
    summary: null,
    info: {
        pendingContracts: 0,
        redeem: 0,
        totale: 0,
        price: 0,
        friends: 0,
        activeRequestFunds: false,
        userFundsGiven: []
    },
    friendsContracts: [],
    resetHome: () => set({ contracts: [], friendsContracts: [], summary: null, info: { pendingContracts: 0, redeem: 0, totale: 0, price: null, friends: 0, activeRequestFunds: false, userFundsGiven: [] } }),
    setFriends: (idFriend: number) => {
        set({ summary: get().summary?.filter((c: any) => c.user.id !== idFriend) });
    },
    getContracts: async () => {
        try {
            const { data } = await api.get("/getSessionsWithDetails");
            let filtered = data.sessions.filter((c: any) => c.info && !(c.status >= 5 && c.status < 13 && (c.price === 0 || c.price === null)));
            filtered= data.sessions.filter((c: any) =>  c.archivedSessionByUser == 0 && c.archivedSession == 0); //rimuovo anche i contratti archiviati dall'utente
            // console.log(filtered, "FILTERED CONTRACTS");
            set({ contracts: filtered });
            // localStorage.setItem('sessions', JSON.stringify(filtered));
        } catch (error: Error | any) {
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        }
    },
    getActiveContract: async (id, callback) => {
        try {
            // const { data } = await api.get("/getSessionsWithDetails");
            // const activeContract = data.sessions.find((c: any) => c.id == id);
            const { data } = await api.get(`/sessions/getSessionById/${id}`);
            const activeContract = data.session;
            set({ activeContract: activeContract });
            callback?.();
        } catch (error: Error | any) {
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        }
    },
    getFriendsContracts: async () => {
        try {
            // const { data } = await retry(api.get, "/sessions/friends", 3);
            const { data } = await api.get("/sessions/friends");
            const groupedContracts = data.friends.reduce((acc: any, friend: any) => {
                if (!acc[friend.id]) {
                    acc[friend.id] = {
                        user: { name: friend.name, surname: friend.surname, id: friend.id, gestito: parseInt(friend.invitedBy) == friend.adminID, invitedDt: dayjs(friend.createdAt).format('DD/MM/YYYY') },
                        allContracts: [],
                        sessions: friend.sessions?.filter((s: any) => s.info && s.status <= 10)
                    }
                    CONTRACT_TYPE.forEach(c => {
                        const filteresFriendSessions = friend.sessions?.filter((s: any) => s.info && s.status <= 10 && !(s.status >= 5 && (s.price === 0 || s.price === null)));
                        const activeTot = filteresFriendSessions?.filter((cc: any) => !!cc.startDate && cc.info && cc.info.kind_utility === c.value && cc.status <= 10).length;
                        const pendingTot = filteresFriendSessions?.filter((cc: any) => !cc.startDate && cc.info && cc.info.kind_utility === c.value && cc.status <= 10).length;
                        if (activeTot || pendingTot) {
                            acc[friend.id].allContracts.push({
                                type: c.label,
                                active: activeTot,
                                pending: pendingTot,
                            });
                        }
                    });
                }

                return acc;
            }
                , []).filter(Boolean);
            set({ friendsContracts: groupedContracts });
        } catch (error: Error | any) {
            // console.log(error, "ERROR IN GET FRIENDS CONTRACTS");
            set({ friendsContracts: [] });
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        }
    },
    getHomeContracts: async () => {
        try {
            const { getContracts, getFriendsContracts } = get();
            await Promise.all([getContracts(), getFriendsContracts()]);

            const { contracts, friendsContracts } = get();
            // console.log(friendsContracts, "FRIENDS CONTRACTS IN GET HOME CONTRACTS");
            const groupedSessions = contracts.filter((c) => c.info).reduce((acc: any, contract: any) => {
                const { name, surname } = contract.info;
                if (!acc[contract.idUser]) {
                    acc[contract.idUser] = {
                        user: { name, surname, id: contract.idUser },
                        allContracts: []
                    }
                    CONTRACT_TYPE.forEach(c => {

                        // console.log(contracts.filter(x => x.status < 10), "CONTRACT");
                        const activeTot = contracts.filter((cc: any) => cc.idUser === contract.idUser && cc.info  && cc.info.kind_utility === c.value && cc.status >= 10 &&  cc.status < 13).length;
                        const pendingTot = contracts.filter((cc: any) => cc.idUser === contract.idUser  && cc.info && cc.info.kind_utility === c.value && cc.status < 10).length;
                        console.log(activeTot, pendingTot, "ACTIVE AND PENDING");
                        if (activeTot || pendingTot) {
                            acc[contract.idUser].allContracts.push({
                                type: c.label,
                                active: activeTot,
                                pending: pendingTot,
                            });
                        }
                    });
                }
                return acc;
            }, []).filter(Boolean);

            set({
                summary: [...groupedSessions, ...friendsContracts.filter((f: any) => f.user.gestito === true && f.allContracts.length > 0)],
            });

            get().getBalance();
        } catch (error: Error | any) {
            // console.log(error, "ERROR IN GET FRIENDS CONTRACTS");
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        }
    },
    getBalance: async () => {
        try {
            const { contracts, friendsContracts } = get();
            const { data } = await api.get("/getUserBalance");
            const pendingC = contracts.filter(c =>  !!c.info && c.status <10 ).length;
            const pendingF = friendsContracts.filter(s => s.user.gestito).reduce((acc: number, f: any) => acc + f.allContracts.reduce((acc: number, c: any) => acc + c.pending, 0), 0);
            set({
                info: {
                    pendingContracts: pendingC + pendingF,
                    redeem: 0,
                    totale: data.revenueSessions,
                    friends: data.friends,
                    price: data.revenue,
                    activeRequestFunds: data?.activeRequestFunds || false,
                    userFundsGiven: data?.userFundsGiven || []
                }
            });
        } catch (error: Error | any) {
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        }
    },
    updatePaymentMethod: async (payload: any, id: number, callback: () => void) => {
        try {
            await api.post(`/sessions/setPaymentMethod/${id}`, payload);
            get().getActiveContract(id);
            get().getHomeContracts();
            callback?.();
        } catch (error: Error | any) {
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        }
    },
}));



// get().getSessionById(id);
// getSessionById: async (id: number) => {
//     try {
//         const { data } = await api.get(`/sessions/getSessionById/${id}`);
//         const contract = data.session;
//         localStorage.setItem('activeContract', JSON.stringify(contract));
//         get().getHomeContracts();
//     } catch (error: Error | any) {
//         const setError = useStoreError.getState().setError;
//         setError(error.response?.data?.message || error.response?.data || error?.message);
//     }

// },